<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('eps_terms') }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div style="margin: 30px 10px;">
      <template v-if="isZhCn">
        <div>
          <div>
            <div>
              <p><strong>EPS </strong><strong>条款和条件</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 最后修改日期：2020年4月23日
              </p>
              <p><strong>请</strong><strong>仔</strong><strong>细阅读</strong><strong>以下条款和条件，其中包含有关</strong><strong>责</strong><strong>任限制和</strong><strong>纠纷</strong><strong>解决的重要信息</strong>   </p>
              <p><strong>EPS </strong><strong>条款和条件</strong>   </p>
              <p>我们已努力通过通俗易懂的表述使以下使用条款（以下简称<strong>“</strong><strong>本使用条款</strong><strong>”</strong>）尽可能容易理解。为了便于您理解，我们将会偶尔使用带定义的术语。此类术语首次使用时，会以粗体形式表示，以方便您辨认。再次出现时，将会以<em>斜体</em>表示（“我们”、“我们的”或“您”、“您的”除外）。在<em>本使用条款</em>中，以下术语的含义如下：   </p>
              <p><strong>关</strong><strong>联</strong><strong>公司</strong>指我们的任何一家营销分公司，包括那些您可能已经通过其网站完成<em>预订</em>的公司；   </p>
              <p><strong>客</strong><strong>户</strong><strong>支持代理</strong>指(i) 我们或我们的<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>之一的客户支持供应商；或 (ii) 我们<em>的关</em><em>联</em><em>公司</em>的客户支持供应商（包括代表他们行动的任何人）；或 (iii) 代表我们或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>担任客户支持供应商的任何人；   </p>
              <p><strong>预订</strong>指您进行的<em>酒店</em><em>预订</em>；   </p>
              <p><strong>集</strong><strong>团</strong><strong>成</strong><strong>员</strong><strong>公司</strong>指直接控制我们或通过一个或多个中间机构间接控制我们的实体；直接受我们控制或通过一个或多个中间机构间接受我们控制的实体；或与我们受同一控制的实体；包括 Travelscape LLC（一家内华达州有限责任公司）、Vacationspot,SL（一家在西班牙注册的公司）和 Travel Partner Exchange S.L（一家在西班牙注册的公司）；   </p>
              <p><strong>酒店</strong><strong>预订</strong>指住宿预订，包括我们和我们的<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>不时提供的套餐中的部分预订；以及，   </p>
              <p><strong>我</strong><strong>们</strong>指特拉华州有限合伙企业 EAN.COM L.P.；   </p>
              <p><strong>您</strong>指浏览我们的<em>信息</em>或进行<em>酒店</em><em>预订</em>的终端个人或企业客户。   </p>
              <p><em>本使用条款</em>规定了您在从事下列活动时须遵循的条件：   </p>
              <ul><li>获取关于<em>酒店</em><em>预订</em>的<em>信息</em> ( <strong>我</strong><strong>们</strong><strong>的</strong>)，以及我们和我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>所提供给我们<em>关</em><em>联</em><em>公司</em>及<em>客</em><em>户</em><em>支持代理</em>的关于酒店预订的信息；以及<strong>信息</strong>
              </li><li>完成<em>预订</em>。
              </li></ul>
              <p>在您开始浏览我们的<em>信息</em>和/或进行<em>预订</em>之前，请仔细阅读以下<em>条款</em>。我们建议您打印一份<em>本使用条款</em>的副本，以作将来参考之用。   </p>
              <p>浏览我们的<em>信息</em>和/或进行<em>预订</em>，即表示您接受并同意遵循<em>本使用条款</em>。如果您不同意<em>本使用条款</em>，请不要使用我们的<em>信息</em>，联系我们的<em>客</em><em>户</em><em>支持代理</em>，或进行<em>预订</em>。   </p>
              <p><strong>其他适用条款</strong>   </p>
              <p><em>本使用条款</em>也指附加的规则和限制，包括<em>酒店</em><em>预订</em>供应商的取消政策（<strong>规则</strong>），这些政策可能适用于您的<em>预订</em>。在完成<em>预订</em>之前，这些<em>规则</em>将提供给您。请根据预订页面上所述的规则进行<em>预订</em>。请仔细阅读这些<em>规则</em>。如果您是为他人<em>预订</em>，您有责任告知其上述<em>规则</em>，并说明他们将受到同样的约束。   </p>
              <p>无论出于什么原因，若有任何人在任何时候违反了包括但不限于<em>本使用条款</em>的规定，我们保留拒绝其访问我们的<em>信息</em>及进行<em>酒店</em><em>预订</em>的权利。   </p>
              <p><strong>本使用条款的</strong><strong>变</strong><strong>更</strong>   </p>
              <p>我们可能会随时通过修改此页面对<em>本使用条款</em>进行更改，并将不时在线发布最新版本。   </p>
              <p>请您在每次<em>预订</em>时检查此网页，留意我们作出的任何变更，因为它们对您具有约束力并与所有事宜相关，但在最后所作变更的日期之前所进行的<em>预订</em>除外（请见右上方）。您在<em>预订</em>时所使用的条款将继续适用于该<em>预订</em>。&nbsp;   </p>
              <p><strong>我</strong><strong>们</strong><strong>的<em>信息</em>用途</strong>   </p>
              <p>我们的<em>信息</em>仅用于帮助您收集旅游信息、确定<em>酒店</em><em>预订</em>的可预订情况、进行合法的<em>预订</em>或以其他方式与我们的<em>客</em><em>户</em><em>支持代理</em>沟通。   </p>
              <p>未经我们书面同意，不得将我们的<em>信息</em>用于其他用途。   </p>
              <p><strong>预订</strong><strong>条件</strong>   </p>
              <p>作为<em>预订</em>条件，您保证：   </p>
              <ul><li>您已年满 18 周岁；
              </li><li>您有权与我们及相关<em>酒店</em><em>预订</em>供应商创设具有约束力的法律协议；
              </li><li>您将仅为您本人或您有权为其预订的其他人（<strong>其他人</strong>）进行<em>预订</em>；
              </li><li>您将向上述<em>其他人</em>说明<em>本使用条款</em>的内容（我们不时更新的内容）以及其他任何适用于<em>预订</em>的条款；
              </li><li>您所提供的关于<em>预订</em>的所有信息（包括代表<em>其他人</em>所提供的任何信息）是准确的、完整的和最新的；另外，
              </li><li>您将遵守以下所述的我们的可接受的使用政策。
              </li><li>将我们的<em>信息</em>用于商业用途；
              </li><li>进行任何投机性、虚假性或欺诈性<em>预订</em>，或根据预测性需求进行任何<em>预订</em>；
              </li><li>未获得我们的明示书面同意，出于任何目的，通过任何机器人软件、蜘蛛软件、刷屏软件或其他自动方式或任何手动流程获取、监视或复制我们的<em>信息</em>；
              </li><li>违反我们的<em>信息</em>上任何机器人排除标头中的限制，或者绕开或回避旨在防止或限制访问我们的<em>信息</em>的其他手段；
              </li><li>实施任何导致或可能导致（由我们决定）我们的基础设施负载过重的行为；
              </li><li>未获得我们的明示书面同意，出于任何目的，深链接至我们的<em>信息</em>的任何部分（包括但不限于任何旅行产品的购买途径）；或者，
              </li><li>未事先获得我们的书面授权，以“框架”、“镜像”或其他方式在任何其他网站中包含我们的<em>信息</em>的任何部分。
              </li></ul>
              <p><strong>可接受的使用政策</strong>   </p>
              <p>在访问我们的<em>信息</em>时，您同意不实施以下行为：   </p>
              <p><strong>第三方</strong><strong>链</strong><strong>接</strong>   </p>
              <p>我们的<em>信息</em>可能包含其他网站和由第三方提供的资源的链接。这些链接仅供您参考，并不表示我们认可这些网站或资源上的资料。   </p>
              <p>我们对这些网站或资源的内容没有任何控制权。特别是，我们对这些网站的隐私或其他做法不承担任何责任。   </p>
              <p><strong><em>预订</em></strong><strong>付款</strong>   </p>
              <p>在提交您的<em>酒店</em><em>预订</em>申请后，您授权我们或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>之一，代表您促成<em>酒店</em><em>预订</em>。   </p>
              <p>您何时对<em>预订</em>进行付款取决于您是否预付其<em>酒店</em><em>预订</em>（<strong>预</strong><strong>付款</strong><strong>预订</strong>），这将在完成<em>预订</em>之前通知您。   </p>
              <p>请注意，这两种支付方式的税款和费用会有所不同。在<em>预订</em>与实际使用<em>酒店</em><em>预订</em>期间，税率和汇率有可能发生变化。   </p>
              <p>可以使用优惠券，但须受其具体条款和条件限制。如果您希望使用优惠券，须对预订进行预付才可使用。   </p>
              <p><em><u>预</u></em><em><u>付款</u></em><em><u>预订</u></em><u>（</u><u>“</u><u>预订</u><u>即付款</u><u>”</u><u>）</u>   </p>
              <p><em>预</em><em>付款</em><em>预订</em>须在<em>预订</em>时付款。您授权我们按总计预订价格进行<em>预订</em>，包括我们<em>关</em><em>联</em><em>公司</em>显示的<em>酒店</em><em>预订</em>价格、任何附加的代缴税款、税费、服务费、我们服务的任何适用税费和我们<em>关</em><em>联</em><em>公司</em>单独收取的附加预订费。   </p>
              <p>如果必须提前支付您的<em>预订</em>费用，或您选择在<em>预订</em>时付款，则由 (1) Travel Partner Exchange S.L 代表我们某个集团成员公司和/或代表旅行预订提供商、(2) 旅行服务提供商直接或 (3) 以我们或我们集团成员公司的名义开展业务的关联公司从您的信用卡中扣除预订总价。<u>到店付款</u> 如果不必提前支付您的<em>预订</em>费用，您可以选择在您入住时付款。 如果您选择“到店付款”的选项，适用的<em>酒店</em><em>预订</em>供应商将在您入住时以当地货币收取款项。   </p>
              <p><u>其他信息</u>   </p>
              <p>当发卡机构和商户（由卡品牌界定，如 Visa、MasterCard 和 American Express）位于不同国家/地区时，一些银行和信用卡公司会向帐户所有人收取一定交易费。汇率（如适用）和任何交易费完全由银行或处理交易的其他机构确定。发卡机构可能从持卡人帐户中收取这些费用。当发卡机构收取费用时，这些费用会与交易金额分开列明在信用卡或借记卡账单上。这意味着您的信用卡或借记卡账单所列的金额可能与根据我们的<em>信息</em>所完成的预订的账单摘要页面所列的金额不同。如果您对这些费用或应用于您预订的任何汇率有任何问题，请联系您的银行。   </p>
              <p>一些住宿供应商在您入住时可能会要求您和/或<em>其他人</em>出示信用卡或提供现金押金，以保障您入住期间的额外支出。此类押金与我们向您收取的<em>预订</em>款项没有任何关联。&nbsp;   </p>
              <p><strong><em>预订</em></strong><strong>的取消或</strong><strong>变</strong><strong>更</strong>   </p>
              <p>预订<em>规则</em>将规定适用的取消期限，在此期限内您可以取消或变更<em>预订</em>（<strong>取消期限</strong>）。在少数情况下，一些<em>预订</em>在完成后不能变更或取消，特定的酒店取消政策将说明这一规定。   </p>
              <p>如获允许，您可以在<em>取消期限</em>内取消或变更<em>预</em><em>付款</em><em>预订</em>，但您须按特定酒店取消政策的规定支付适用的取消或变更费用。   </p>
              <p>如果您获准取消或变更<em>预订</em>，但未在<em>取消期限</em>结束之前取消或变更，则无论是否入住，您都必须支付取消费用，包括适用的夜间<em>酒店</em><em>预订</em>价格、税费或代缴税款（如适用）、服务费和我们<em>关</em><em>联</em><em>公司</em>单独收取的额外预订费（<strong>取消</strong><strong>罚</strong><strong>金</strong>）。   </p>
              <p>如果您没有在<em>预订</em>的第一晚入住，但计划在后面的晚上入住，您必须在预订的第一晚当天之前与我们的<em>关</em><em>联</em><em>公司</em>确认。如不进行确认，您的<em>预订</em>可能会被取消，且您可能需要支付<em>取消</em><em>罚</em><em>金</em>。   </p>
              <p><strong>团</strong><strong>体</strong><strong>预订</strong>   </p>
              <p>请注意，我们不能保证任何住宿供应商可提供同一入住日期的超过 8 间客房的预订（<strong>团</strong><strong>体</strong><strong>预订</strong>）。如果您在线进行<em>团</em><em>体</em><em>预订</em>，我们有权取消该预订并向您收取<em>规则</em>中所述的任何取消费用。如果您为<em>团</em><em>体</em><em>预订</em>缴纳了不可退回的押金，您的押金将不予退还。   </p>
              <p><strong>欺</strong><strong>诈</strong><strong>性</strong><strong><em>预订</em></strong>   </p>
              <p>如果有迹象显示任何<em>预订</em>（包括任何相关的支付卡）为或涉及任何可疑欺诈、滥用或可疑活动，我们或我们<em>关</em><em>联</em><em>公司</em>有权取消任何与您的姓名或电子邮件地址相关联的<em>预订</em>。此外，我们还有权验证（即预授权）用于<em>预订</em>支付的任何信用卡。如果您或任何用于<em>预订</em>支付的信用卡持有人进行任何欺诈活动，我们保留采取任何必要法律行动的权利，您和/或持卡人可能须对我们和我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>的损失（包括诉讼费用和损害赔偿）承担法律责任。如对取消<em>预订</em>存在疑虑，请联系我们的<em>关</em><em>联</em><em>公司</em>。   </p>
              <p><strong>套餐</strong>   </p>
              <p>如果您的<em>酒店</em><em>预订</em>为与另一旅行产品（如航班）（<strong>套餐或相关旅行安排</strong>）一起提供，则向您提供此<em>套餐</em>或相关旅行安排中第一个旅行产品的<em>关</em><em>联</em><em>公司</em>将对该<em>套餐</em>或相关旅行安排的任何变更或义务负责。   </p>
              <p><strong>税</strong><strong>费</strong>   </p>
              <p>您认可，除下文所述的服务款项产生的税款外，我们不会为相关税务机关代收税款。在适当情况下，住宿供应商会针对特定费用及税额（如果适用）向我们开具发票。住宿供应商负责向相关税务机关缴纳适用税额。我们向您收取的<em>预</em><em>付</em><em>费预订</em>代缴税款是指我们就客房出租所产生的税金而向住宿供应商支付的预计税额（例如，销售税和使用税、占用税、房屋税、消费税、增值税等）。可征税性和相应的税率会因地点的不同而发生变化。我们向住宿供应商实际支付的税额可能与代缴税款金额不同，具体取决于您在实际入住酒店时生效的税率和可征税性。   </p>
              <p>您认可我们向您提供收费服务以促成<em>酒店</em><em>预订</em>。我们收取服务费，作为向您提供<em>预订</em>服务的报酬。我们的服务费随<em>酒店</em><em>预订</em>金额和类型的不同而异。销售税、入住税和/或当地酒店占用税将根据我们在特定辖区内提供服务所收取的费用（服务费和/或设施费）进行征收。我们提供服务的实际税额可能因您在实际入住酒店时的有效税率而异。   </p>
              <p>您承认某些国家的政府机构可能会征收额外的销售税、入住税和/或当地酒店占用税（旅客税），这些税费必须在当地支付。缴纳此类附加税的义务由您单方面承担。   </p>
              <p><strong>国</strong><strong>际</strong><strong>旅行</strong>   </p>
              <p>您应负责确保您符合任何适用的国外入境要求，并确保您的护照和签证（过境签、商务签、旅游签或其他签证）等旅行证件已准备妥当，并且符合任何其他国外入境要求。 &nbsp;   </p>
              <p>我们提供<em>酒店</em><em>预订</em>尤其是国际目的地的预订服务，并不声明或担保我们建议您前往这些目的地旅行，也不声明或担保前往这些目的地旅行没有危险，对于前往此类目的地旅行可能造成的损害或损失，我们不承担任何责任。我们建议您查看您国家的国外旅行准则，以了解更多详情。&nbsp;   </p>
              <p><strong>免</strong><strong>责</strong><strong>声明</strong>   </p>
              <p>我们和我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>将做出合理的努力更新我们的<em>信息</em>，以保证其准确性。但是，我们的<em>信息</em>由我们的<em>酒店</em><em>预订</em>供应商提供。因此：   </p>
              <ul><li>我们的<em>信息</em>可能会随时被更换、补充、修改或删除，任何信息不可用或更改可能会随时出现，恕不另行通知，而且我们或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>对此不承担责任；
              </li></ul>
              <ul><li>我们对我们的<em>信息</em>（包括但不限于<em>酒店</em><em>预订</em>价格、酒店照片、酒店设施列表和酒店一般描述）的任何错误或其他不准确性不承担任何责任；
              </li><li>我们不保证特定的<em>酒店</em><em>预订</em>的可获得性；&nbsp;
              </li><li>我们不对我们的<em>信息</em>针对任何目的的适用性作任何声明；
              </li><li>我们的<em>信息</em>所显示的酒店评级仅作为一般准则之用，我们和我们的<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>不保证此类评级的准确性；&nbsp;
              </li><li>列入或提供任何<em>酒店</em><em>预订</em>不构成对我们或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>对<em>酒店</em><em>预订</em>的任何认可或推荐；
              </li><li>不担保和保证我们的<em>信息</em>和服务或者我们或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>发送的任何电子邮件不含病毒或其他有害组件；以及
              </li><li>服务均按“原样”提供，不附加任何保证。
              </li></ul>
              <p>我们明确保留对任何价格错误和/或基于价格误报的预订进行更正的权利。在此情况下，您可选择以修改后的价格（如有）保留预订，或者取消您的预订且不扣除任何罚金。   </p>
              <p><strong>我</strong><strong>们对</strong><strong>您的</strong><strong>责</strong><strong>任</strong>   </p>
              <p>向我们提供<em>酒店</em><em>预订</em>的酒店和其他供应商是独立的承包商，而非我们的代理或员工或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>。   </p>
              <p>我们和我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>对以下行为不承担责任：   </p>
              <ul><li>任何<em>关</em><em>联</em><em>公司</em>或<em>酒店</em><em>预订</em>供应商的作为、错误、疏忽、声明、保证、违约或失职行为；或者
              </li><li>因此而造成的人身伤害、死亡、财产损失或其他损害或开支。&nbsp;
              </li></ul>
              <p>如出现以下任何情况之一，我们和我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>将不承担责任和退款：   </p>
              <ul><li>延误；
              </li><li>取消；
              </li><li>超订；
              </li><li>罢工；
              </li><li>不可抗力事件；或
              </li><li>其他超出我们直接控制范围的情况；&nbsp;
              </li></ul>
              <p>我们对任何额外开支、疏忽、延误、改变航线或者任何政府或当局的行为概不负责。&nbsp;   </p>
              <p>在适用法律允许的最大范围内，在任何情况下，我们或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>对以下行为所造成或与之相关的任何直接、间接、惩罚性、附带性、特殊或后果性损害不承担任何责任：   </p>
              <ul><li>您访问我们的<em>信息</em>；或
              </li><li>延迟或无法访问我们的<em>信息</em>（包括但不限于任何电脑病毒、信息、软件、链接网站和<em>预订</em>）；
              </li></ul>
              <p>无论是否基于失职、合约、侵权、严格责任、消费者保护法律或其他理论，即使我们或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>已被告知发生此类损害的可能性。   </p>
              <p>尽管存在上述限制，但如果我们或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>被认定应对因上述原因引起或与之相关的任何损失或损害负责，则我们或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>的责任在任何情况下总计不高于以下费用中的较高者：   </p>
              <ul><li>您支付给我们、我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>或<em>关</em><em>联</em><em>公司</em>的与<em>预订</em>相关的<em>预订</em>费用；或
              </li><li>一百美元 (US $100.00) 或等值的当地货币。
              </li></ul>
              <p>我们不以任何方式排除或限制我们对适用法律包含的任何行为的责任。   </p>
              <p>责任限制反映出您和我们之间的风险分配。   </p>
              <p>即使发现<em>本使用条款</em>中的任何有限补救措施未能达到其基本目的，本节中的限制性规定仍将继续有效和适用。   </p>
              <p><em>本使用条款</em>中所规定的责任限制旨在保障我们和我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>的利益。   </p>
              <p><strong>赔偿</strong>   </p>
              <p>对于第三方因下列原因向我们、我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>及其任何高管、总监、员工和代理提出的任何索赔、诉讼、要求、追偿金、损失、损害赔偿金、手续费、罚金或其他任何类型或性质的费用或开支，包括但不限于合理的法律费和会计费，您同意为其进行辩护并做出相应赔偿：   </p>
              <ul><li>您违反<em>本使用条款</em>；
              </li><li>您违反任何法律规定或影响第三方权利；或
              </li><li>您使用我们的<em>信息</em>。
              </li></ul>
              <p><strong>知</strong><strong>识产</strong><strong>权</strong>   </p>
              <p>我们的<em>信息</em>（包括但不限于<em>酒店</em><em>预订</em>的价格和可预订情况）为我们或我们的供应商所有。   </p>
              <p>尽管我们同意您可使用我们的<em>信息</em>以复制数量有限的行程单及<em>预订</em>相关的其他任何信息，但您同意不得修改、复制、分发、传输、展示、执行、复印、发布、许可、创造衍生作品、转让、销售或转售有关和/或包括<em>酒店</em><em>预订</em>和我们的<em>信息</em>的任何信息、软件、产品或服务。   </p>
              <p><strong>版</strong><strong>权</strong><strong>和商</strong><strong>标</strong><strong>声明</strong>   </p>
              <p>我们的<em>信息</em>所有内容均归 &copy;2018 Expedia, Inc. 版权所有。保留所有权利。Expedia、Expedia Affiliate Network、EAN、Travelnow、Hotels.com、Expedia Affiliate Network 徽标和 Travelnow 徽标和 Hotels.com 徽标均是 Expedia, Inc. 在美国和/或其他国家/地区的商标或注册商标。本协议中涉及的其他徽标与产品和公司名称可能是其各自所有者的商标。我们对第三方操作的网站内容不承担责任。   </p>
              <p>地图中所用的 OpenStreetMap 地理数据由 (c) OpenStreetMap 提供，根据开放数据库许可 (ODbL) 使用。&nbsp;&nbsp;   </p>
              <p>如果您了解到侵犯我们品牌相关权利的行为，请通过发送电子邮件至 hotline@expedia.com 告知我们。本电子邮件地址仅用于接收有关品牌侵权的信息。   </p>
              <p><strong>适用法律</strong>   </p>
              <p>除非另有说明，就所有因使用<em>本使用条款</em>而引起的争议或与我们的<em>信息</em>的使用相关的争议中，您特此认同美国华盛顿州金县法院的专属管辖权和地点。在任何不承认<em>本使用条款</em>的全部有效性的司法管辖区使用我们的<em>信息</em>，均视作未经授权使用。   </p>
              <p>如果是 Travel Partner Exchange S.L 收取款项并从您的卡里扣款，则该付款交易应受西班牙法律约束。您同意，西班牙法院有权审理和确定涉及 Travel Partner Exchange S.L 的付款交易所引起的任何争议。   </p>
              <p><strong>纠纷</strong>   </p>
              <p>我们和我们的<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>都致力于确保客户满意度，所以如果您有问题或争端，我们将努力解决您的问题。如果我们未做到这点，您可按本节中的说明提出索赔。   </p>
              <p>通过<em>预订</em>时提供给您的客户服务联系信息联系我们，即表示您同意给我们一个机会来解决关于我们的<em>信息</em>、<em>预订</em>、与我们<em>客</em><em>户</em><em>支持代理</em>的任何业务、任何<em>酒店</em><em>预订</em>或我们所作的任何声明的任何纠纷或索赔（<strong>索</strong><strong>赔</strong>）。如果我们无法在 60 天之内解决您的<em>索</em><em>赔</em>，则您可按以下规定通过仲裁或向小额索赔法院寻求救济。   </p>
              <p>任何<em>索</em><em>赔</em>均将通过有约束性的仲裁进行解决，而不是通过法院，除非您个人向有司法权的小额索赔法院提出<em>索</em><em>赔</em>。这包括您针对我们、我们的子公司、旅行供应商或通过我们提供产品或服务的任何公司（其均为本仲裁协议的受益人）提出的任何<em>索</em><em>赔</em>。这也包括在您接受本<em>本使用条款</em>之前引起的任何<em>索</em><em>赔</em>，无论以前版本的<em>本使用条款</em>是否需要仲裁。   </p>
              <p>没有法官或陪审团参与仲裁程序，且法院对仲裁裁决的审查受到限制。但是，仲裁员个人可判决与法院相同的赔偿损害金和救济（包括法定损害赔偿金、律师费和相关费用），且如同法院一样遵循和执行<em>本使用条款</em>。&nbsp;   </p>
              <p>仲裁将由美国仲裁协会 (AAA) 根据其仲裁规定进行，包括 AAA 消费者规定。AAA 规定在 <a href="http://www.adr.org/">www.adr.org</a> 上提供，也可致电 1-800-778-7879 索取。所有申请费、行政管理费和仲裁员费用的支付均受 AAA 规定管辖，除非本节另有规定。如果您<em>索</em><em>赔</em>的总金额不足 10,000 美元，则我们将向您报销您支付给 AAA 的申请费，并将支付仲裁员的费用，除非仲裁员确定您的<em>索</em><em>赔</em>乃轻率提出。您可根据书面申请选择电话仲裁，或在您所居住州或共同协定的其他地点亲自参加仲裁。   </p>
              <p>为了开始仲裁程序，您必须将描述<em>索</em><em>赔</em>的仲裁申请函发送给“Expedia 法律部门：仲裁索赔经理”，寄送地址为：Expedia, Inc., 333 108th Ave N.E.Bellevue, WA 98004。如果我们申请向您执行仲裁，则会向您提供的电子邮件地址或街道地址发送通知。仲裁申请方必须向 AAA 另行提供一份申请并寄送至立案服务部（地址为：1101 Laurel Oak Road, Suite 100, Voorhees, NJ 08043），或在 <a href="http://www.adr.org/">www.adr.org</a> 网站在线提出申请，或向任何 AAA 办事处提出申请。   </p>
              <p>将仅按个人诉讼而不是集体诉论、合并诉讼或代表诉讼执行任何和所有法律程序来解决<em>索</em><em>赔</em>。如果由于任何原因在法院而不是通过仲裁执行<em>索</em><em>赔</em>程序，则我们双方均放弃对陪审团审判的任何权利。联邦仲裁法案和联邦仲裁法律适用于本协议。仲裁裁决可由具备司法管辖权的任何法院予以确认。   </p>
              <p><strong>一般条款</strong>   </p>
              <p>您同意，您与我们或我们<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>之间不因<em>本使用条款</em>或您对我们的<em>信息</em>的使用而存在任何合资、合作或雇佣关系。   </p>
              <p>您承认 Travelscape, LLC 或 VacationSpot, SL（或我们指定的任何其他<em>集</em><em>团</em><em>成</em><em>员</em><em>公司</em>）将被任何相关司法管辖区内的任何税务机关视为 2006/112/EC 指令所指的 VAT（增值税）意义上的<em>预</em><em>付款</em><em>预订</em>和<em>套餐</em>的<em>酒店</em><em>预订</em>供应商。   </p>
              <p>我们履行<em>本使用条款</em>须遵守现行的法律和法律程序，<em>本使用条款</em>的任何内容都不得限制我们的以下权利：服从与您使用我们的<em>信息</em>或就该等使用向我们提供或由我们收集之信息相关的执法要求以及其他政府或法律要求。   </p>
              <p>我们有权把我们在<em>本使用条款</em>项下的权利和义务转让给其他组织，但这不会影响<em>本使用条款</em>下您的权利或我们的义务。 经我们书面同意，您仅可把您在<em>本使用条款</em>项下的权利或义务转让给他人。   </p>
              <p>在适用法律允许的范围内，您同意，对于由于您访问或使用我们的<em>信息</em>而引起的或与此相关的任何索赔或诉讼，您会在此类索赔权利或诉因产生之日起两 (2) 年内提起，否则将视为您放弃此类索赔或诉讼，且此放弃不可撤消。   </p>
              <p>如果<em>本使用条款</em>的任何部分被认定为无效、非法或不可执行，其余规定的有效性、合法性和可执行性不得以任何方式受到影响或削弱。我们在任何时候未能执行或延迟执行<em>本使用条款</em>的任何规定，均不表示我们放弃在将来执行<em>本使用条款</em>相同规定或任何其他规定之权利。   </p>
              <p><em>本使用条款</em>（以及<em>本使用条款</em>所引用的所有其他条款和条件）构成了您与我们之间就我们的<em>信息</em>和任何<em>预订</em>而形成的完整协议，并取代您与我们之间就我们的<em>信息</em>和/或<em>预订</em>所达成的所有之前或同时期的通信和提议（包括电子形式、口头或书面形式）。在基于<em>本使用条款</em>或是与<em>本使用条款</em>有关的司法和行政诉讼中，<em>本使用条款</em>的打印版本和以电子表格形式发出的通知均可采纳，其采纳程度和条件与最初以打印方式生成和保存的商务文件及记录完全相同。   </p>
              <p>我们的<em>信息</em>中提及的公司、产品、人、角色、和/或数据的虚构名称并不代表任何真实的个人、公司、产品或者事件。   </p>
              <p>我们保留本协议没有明确授权的所有权利。   </p>
              <p><strong>我</strong><strong>们</strong><strong>的</strong><strong>详细</strong><strong>信息</strong>   </p>
              <p>Travel Partner Exchange S.L 设有自己的注册办事处，邮编 38002，地址：Paseo Milicias de Garachico 1, Edificio Hamilton, oficina 79, 38002 Santa Cruz de Tererife, Islas Canarias, Spain。   </p>
              <p>Travelscape LLC 是以下所列各州的注册旅行社：   </p>
              <ul><li>加利福尼亚州注册号：2083930-50
              </li><li>佛罗里达州注册号：ST36407
              </li><li>爱荷华州注册号：677
              </li><li>内华达州注册号：20020759
              </li><li>华盛顿州注册号：602-617-174
              </li></ul>
              <p>在加利福尼亚州注册为卖方并不等同于已获得该州批准。   </p>
              <p>纽约州税务登记：   </p>
              <p>Travelscape (d/b/a Expedia Travel) 的纽约销售税供应商登记编号为 880392667，其纽约市酒店占用税登记编号为 033960。   </p>
              <p>请单击此处了解其他信息： <br></p><p><a href="https://images.trvl-media.com/media/content/expus/graphics/other/travelscape_nys_certificate_of_authority.pdf" target="_blank">纽约州授权证书</a>   </p>
              <p><a href="https://images.trvl-media.com/media/content/expus/graphics/other/travelscape_nyc_certificate_of_authority.pdf" target="_blank">纽约市授权证书</a>   </p>
              <p>&copy;2018 EXPEDIA, INC.保留所有权利。   &nbsp;
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <div>
            <div>
              <p><strong>EPS Terms and
                Conditions</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <strong>&nbsp;Last Revised On:&nbsp; April 8th, 2021</strong><span></span>
              </p>
              <div>
                <p><strong><span style="font-family:Calibri; font-size:12.0pt; "></span></strong></p>
                <p><a name="a870926"></a></p>
                <p><strong>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY </strong><strong>AS THEY CONTAIN IMPORTANT
                  INFORMATION ABOUT LIMITATIONS OF LIABILITY AND RESOLUTION OF DISPUTES </strong><span></span>
                </p>
                <p><strong>EPS TERMS AND CONDITIONS</strong>
                </p>
                <p>We have tried to make these terms of use (the <strong>Terms</strong>) as easy to understand as possible by
                  using plain English. To make it easier for you to follow, we have occasionally used definitions. When a
                  definition is first used, you will be able to see it clearly in bold text. If we use it again in these Terms,
                  it will appear with capital letters (unless it is a reference to we, us or our or you or your). In the Terms,
                  the following words have the following meanings:
                </p>
                <p><strong>Affiliate </strong>means any of our marketing affiliates, including those through whose site you may
                  have made the Booking;
                </p>
                <p><strong>Customer Support Agent </strong>means (i) our or one of our Group Member’s customer support
                  providers; or (ii) our Affiliates’ customer support providers (including any persons acting on their behalf);
                  or (iii) any persons acting as customer support providers on behalf of us or our Group Members;
                </p>
                <p><strong>Booking </strong>means your making of a Hotel Reservation;
                </p>
                <p><strong>Group Member </strong>means an entity that, directly or indirectly, through one or more
                  intermediaries, controls or is controlled by, or is under common control with us, including Travelscape LLC (a
                  Nevada limited liability company) and Vacationspot, SL (a Spanish registered company);
                </p>
                <p><strong>Hotel Reservations </strong>means the accommodation reservations, including those which are part of a
                  package, made available by us and our Group Members from time to time; and
                </p>
                <p><strong>we</strong>, <strong>us</strong>, <strong>our </strong>means EAN.com L.P, a Delaware limited
                  partnership;
                </p>
                <p><strong>you, your</strong> means the individual or corporate end customer browsing our Information or making
                  Hotel Reservations.
                </p>
                <p>The Terms tell you the terms on which you may:
                </p>
                <ul>
                  <li>access the information about Hotel Reservations (<strong>our</strong> <strong>Information</strong>) we and
                    our Group Members provide to our Affiliates and Customer Support Agents about the Hotel Reservations; and
                  </li>
                  <li>complete a Booking. </li>
                </ul>
                <p>Please read these Terms carefully before you start to browse our Information and/or make a Booking. We
                  recommend that you print a copy of these Terms for future reference.
                </p>
                <p>By browsing our Information and/or making a Booking, you confirm that you accept these Terms and that you
                  agree to comply with them. If you do not agree to these Terms, you must not use our Information, contact our
                  Customer Support Agents or make any Bookings.
                </p>
                <p><strong>OTHER APPLICABLE TERMS</strong>
                </p>
                <p>These Terms also refer to additional rules and restrictions, including cancellation policies of suppliers of
                  Hotel Reservations (<strong>Rules</strong>) which may apply to your Booking. These Rules will be made
                  available to you prior to completion of the Booking and any Booking is made subject to the Rules as set out on
                  the booking page. Please read these Rules carefully. If you are making a Booking on behalf of any other
                  persons, you are responsible for bringing these Rules to the attention of such persons and advising them they
                  will be bound by the same.
                </p>
                <p>We retain the right, at our sole discretion, to deny access to our Information and Hotel Reservations at any
                  time and for any reason, including, but not limited to, for breach of these Terms.<span></span>
                </p>
                <p><strong>CHANGES TO THESE TERMS</strong>
                </p>
                <p>We may revise these Terms at any time by amending this page and will publish the updated version online from
                  time to time.
                </p>
                <p>Please check this web page each time you make a Booking to take notice of any changes we have made, as they
                  are binding on you in relation to all matters except any Bookings made prior to the date of the last set of
                  changes (see top right). The Terms that applied at the time you made the Booking will continue to apply to
                  such Booking. &nbsp;
                </p>
                <p><strong>PURPOSE OF OUR INFORMATION </strong>
                </p>
                <p>Our Information is provided solely to assist you in gathering travel information, determining the
                  availability of Hotel Reservations, making legitimate Bookings or otherwise communicating with our Customer
                  Support Agents. </p>
                <p>Unless we agree otherwise in writing, you may not use our Information for any other purpose.
                </p>
                <p><strong>CONDITIONS OF MAKING A BOOKING</strong>
                </p>
                <p>As a condition of making a Booking, you warrant that:
                </p>
                <ul>
                  <li>you are at least 18 years of age;
                  </li>
                  <li>you have the authority to create a binding legal agreement with us and the relevant Hotel Reservation
                    supplier(s);
                  </li>
                  <li>you will only make Bookings for you or for other persons for whom you have authority to make such Bookings
                    (<strong>Other Persons</strong>);
                  </li>
                  <li>you will advise such Other Persons about these Terms (as updated by us from time to time) and any other
                    applicable terms that apply to a Booking; </li>
                  <li>all information supplied by you (including any information supplied on behalf of Other Persons) in respect
                    of a Booking is accurate, complete and up to date; and
                  </li>
                  <li>you will comply with our Acceptable Use Policy set out below. </li>
                  <li>use our Information for any commercial purpose; </li>
                  <li>make any speculative, false, or fraudulent Bookings or any Booking in anticipation of demand; </li>
                  <li>access, monitor or copy any of our Information using any robot, spider, scraper or other automated means
                    or any manual process for any purpose without our express written permission; </li>
                  <li>violate the restrictions in any robot exclusion headers on our Information or bypass or circumvent other
                    measures employed to prevent or limit access to our Information; </li>
                  <li>take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately
                    large load on our infrastructure; </li>
                  <li>deep-link to any portion of our Information (including, without limitation, the purchase path for any
                    Travel Products) for any purpose without our express written permission; or </li>
                  <li>"frame", "mirror" or otherwise incorporate any part of our Information into any other website without our
                    prior written authorization.
                  </li>
                </ul>
                <p><strong>ACCEPTABLE USE POLICY</strong>
                </p>
                <p>When accessing our Information you agree not to: <br></p>
                <p>
                </p>
                <ul>
                  <li>use our Information for any commercial purpose;</li>
                  <li>make any speculative, false, or fraudulent reservation or any reservation in anticipation of demand;</li>
                  <li>access, monitor or copy any content or Information using any robot, spider, scraper or other automated
                    means or any manual process for any purpose without the express written permission of us and any supplier
                    that owns such content or information;</li>
                  <li>bypass or circumvent measures employed to prevent or limit access to our Information;</li>
                  <li>take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately
                    large load on our infrastructure;</li>
                  <li>"frame," "mirror" or otherwise incorporate any part of our Information into any other website or
                    application. </li>
                </ul>
                <p>If your booking or account shows signs of fraud, abuse or suspicious activity, we or our suppliers may cancel
                  any travel or service reservations associated with your name, email address or account, and close any
                  associated accounts. If you have conducted any fraudulent activity, we and our suppliers reserve the right to
                  take any necessary legal action and you may be liable for monetary losses to us and our suppliers, including
                  litigation costs and damages. To contest the cancellation of a booking or freezing or closure of an account,
                  please contact us. </p>
                <p><strong>THIRD PARTY LINKS</strong>
                </p>
                <p>Our Information may contain links to other sites and resources provided by third parties. Such links are
                  provided for your information only and do not imply any endorsement of the material contained within such
                  sites or resources.
                </p>
                <p>We have no control over the contents of those sites or resources. In particular, we are not responsible for
                  the privacy or other practices of such sites.
                </p>
                <p><strong>PAYMENT FOR YOUR BOOKING</strong>
                </p>
                <p>Upon submitting your Hotel Reservation request, you authorize us or one of our Group Members to facilitate
                  Hotel Reservations on your behalf.
                </p>
                <p>When you make payment for a Booking depends on whether you prepay for your Hotel Reservation (<strong>Prepaid
                  Booking</strong>) or not, which will be notified to you prior to completion of your Booking. &nbsp;
                </p>
                <p>Please note that taxes and fees vary between the two payment options. Tax rates and foreign exchange rates
                  could change in the time between Booking and your actual use of the Hotel Reservation.
                </p>
                <p>Coupons may be available but will always be subject to their own specific terms and conditions. If you wish
                  to use a coupon, it can only be applied to Prepaid Bookings.
                </p>
                <p><u>Prepaid Bookings (“Pay Now”)</u>
                </p>
                <p>Prepaid Bookings are paid for by you at the time of making the Booking. You authorise us to make the Booking
                  for the total reservation price, which includes the Hotel Reservation rate, displayed by our Affiliate, plus
                  any additional tax recovery charges, taxes, service fees, any applicable taxes on our services and any
                  additional booking fee separately charged by our Affiliate.
                </p>
                <p>If prepayment for your Booking is required or you choose to pay at the time of Booking, the total price will
                  be charged to your credit card by: (1) Travelscape, LLC taking such payment on behalf of one of our Group
                  Members and/or on behalf of the provider of the Hotel Reservation, (2) the travel service provider directly,
                  or (3) an affiliate doing business on behalf of us or our Group Member.
                </p>
                <p><u>Pay Later </u>
                </p>
                <p>If prepayment for your Booking is not required, you can choose to pay at the time you use the Hotel
                  Reservation. </p>
                <p>If you select this “pay later” option, the applicable Hotel Reservation supplier will take payment for the
                  Hotel Reservation in the local currency at the time you use the Hotel Reservation.
                </p>
                <p><u>Additional Information</u>
                </p>
                <p>Some bank and card companies charge their account holders a transaction fee when the card issuer and the
                  merchant location (as defined by the card brand, e.g. Visa, MasterCard, American Express) are in different
                  countries. The currency exchange rate, if applicable, and any transaction fee is determined solely by the bank
                  or other agency processing the transaction. These fees may be applied by the card issuer as a charge to the
                  cardholders account. When applied by the card issuer, the fees will be listed separately from the transaction
                  amount on a credit or debit card statement. This means the amount listed on your credit or debit card
                  statement may be a different figure than the figure shown on the billing summary page for a reservation booked
                  on our Information. If you have any questions about these fees or any exchange rate applied to your booking,
                  please contact your bank. </p>
                <p>Some accommodation suppliers may require you and/or the Other Persons to present a credit card or cash
                  deposit upon check-in to cover additional expenses incurred during your stay.&nbsp; Such deposit is unrelated
                  to any payment received by us for your Booking.&nbsp; </p>
                <p><strong>CANCELLATION OR MAKING CHANGES TO YOUR BOOKING </strong>
                </p>
                <p>The Rules will set out the applicable cancellation period during which you can cancel or make changes to your
                  Booking (the <strong>Cancellation Period</strong>). In limited cases, some Bookings cannot be changed or
                  cancelled after they are made and this will be indicated in the specific hotel’s cancellation policy.
                </p>
                <p>If permitted, you may cancel or change your prepaid Booking during the Cancellation Period but you will be
                  charged the applicable cancellation or change fee as set out in the specific hotel’s cancellation policy.
                </p>
                <p>If you are allowed to cancel or make changes to your Booking but do not do so before the end of the
                  Cancellation Period, you will be liable to pay a cancellation charge equal to applicable nightly Hotel
                  Reservation rate(s), taxes or tax recovery charges (as applicable), service fees and any additional booking
                  fee separately charged by our Affiliate (the <strong>Cancellation Penalty</strong>) whether or not you use the
                  Hotel Reservation or not.
                </p>
                <p>If you do not show for the first night of your Booking but plan to check-in for subsequent nights, you must
                  confirm with our Affiliate no later than the date of the first night of your reservation. Failure to do so may
                  result in your Booking being cancelled and you being charged the Cancellation Penalty.
                </p>
                <p><strong>GROUP BOOKINGS</strong>
                </p>
                <p>Please note that we cannot guarantee reservations for more than 8 rooms with any accommodation supplier for
                  the same accommodation stay dates (<strong>Group Booking</strong>). If you make a Group Booking online, we may
                  cancel such Group Booking and charge you any cancellation fee set out in the Rules. If you paid a
                  non-refundable deposit in respect of your Group Booking, your deposit will be forfeited.
                </p>
                <p><strong>FRAUDULENT BOOKINGS</strong>
                </p>
                <p>If any Booking (including any associated payment cards) shows signs or is connected to any suspected fraud,
                  abuse or suspicious activity, we or our Affiliate may cancel any Booking associated with your name or email
                  address.&nbsp; In addition, we may verify (i.e. preauthorize) any credit card used for payment of a
                  Booking.&nbsp; If you or the holder of any credit card used for payment of a Booking have conducted any
                  fraudulent activity, we reserve the right to take any necessary legal action and you and/or the card holder
                  may be liable for our and our Group Member’s losses, including litigation costs and damages.&nbsp; To contest
                  the cancellation of a Booking, please contact our Affiliate.
                </p>
                <p><strong>PACKAGES</strong>
                </p>
                <p>If your Hotel Reservation has been provided to you in conjunction with another travel product (e.g. a flight)
                  (a <strong>Package </strong>or a <strong>Linked Travel Arrangement</strong>), then the Affiliate who has
                  provided this Package, or the first travel component of the Linked Travel Arrangement, to you, will be
                  responsible for any changes or liabilities regarding that Package or Linked Travel Arrangement.
                </p>
                <p><strong>TAXES</strong>
                </p>
                <p>You acknowledge that, except as provided below with respect to tax obligations on the amount we retain for
                  our services, we do not collect taxes for remittance to applicable taxing authorities. The accommodation
                  suppliers invoice us for certain charges, including, where appropriate, tax amounts. The accommodation
                  suppliers are responsible for remitting the applicable taxes to the applicable taxing jurisdictions. The tax
                  recovery charges which we charge you on Prepaid Bookings represent the estimated taxes (e.g. sales and use,
                  occupancy, room tax, excise tax, value added tax, etc) that we pay to the accommodation supplier for taxes due
                  on the rental of the room. Taxability and the appropriate tax rate vary depending on the location. The actual
                  tax amounts paid by us to the accommodation suppliers may vary from the tax recovery charge amounts, depending
                  upon the rates, taxability, etc. in effect at the time of your actual use of the Hotel Reservation.
                </p>
                <p>You acknowledge that we provide you services to facilitate the booking of Hotel Reservations for a
                  consideration. We retain our service fees as compensation for servicing your Booking. Our service fees vary
                  based on the amount and type of Hotel Reservation. Sales, use and/or local hotel occupancy taxes are imposed
                  on the amounts that we charge for our services (service fee and/or facilitation fee) in certain jurisdictions.
                  The actual tax amounts on our services may vary depending on the rates in effect at the time of your actual
                  use of the Hotel Reservation.
                </p>
                <p>You acknowledge that authorities in certain countries may impose additional sales, use and/or local hotel
                  occupancy taxes (<em>e.g.</em>, a tourist tax), which have to be paid locally. You are solely responsible for
                  paying such additional taxes.
                </p>
                <p><strong>INTERNATIONAL TRAVEL</strong>
                </p>
                <p>You are responsible for ensuring that you meet any applicable foreign entry requirements and that your travel
                  documents, such as passports and visas (transit, business, tourist, and otherwise), are in order and any other
                  foreign entry requirements are met. &nbsp;
                </p>
                <p>By offering Hotel Reservations in particular international destinations, we do not represent or warrant that
                  travel to such areas is advisable or without risk, and are not liable for damages or losses that may result
                  from travel to such destinations. We recommend you check your country’s foreign travel guidance for further
                  details. &nbsp;
                </p>
                <p><strong>DISCLAIMER </strong>
                </p>
                <p>We and our Group Members will make reasonable efforts to update our Information to ensure its accuracy.
                  However, our Information is provided by the suppliers of our Hotel Reservations. As such:
                </p>
                <ul>
                  <li>our Information may be changed, enhanced, amended or deleted at any time, any non-availability or change
                    may occur at any time without notice and without liability to us or our Group Members;
                  </li>
                </ul>
                <ul>
                  <li>we disclaim all liability for any errors or other inaccuracies relating to our Information, (including,
                    without limitation, the pricing of Hotel Reservations, photographs of hotels, lists of hotel amenities and
                    general hotel descriptions);
                  </li>
                  <li>we make no guarantees about the availability of specific Hotel Reservations;&nbsp; </li>
                  <li>we make no representations about the suitability of our Information for any purpose;
                  </li>
                  <li>hotel ratings displayed in our Information are intended as general guidelines only and we and our Group
                    Members cannot guarantee the accuracy of such ratings;&nbsp; </li>
                  <li>the inclusion or offering of any Hotel Reservation does not constitute any endorsement or recommendation
                    of such Hotel Reservation by us or our Group Members;
                  </li>
                  <li>disclaim all warranties and conditions that our Information, and services or any email sent from us, or
                    our Group Members are free of viruses or other harmful components and all our Information; and
                  </li>
                  <li>services are provided “as is” without warranty of any kind. </li>
                </ul>
                <p>We expressly reserve the right to correct any pricing errors and/or Bookings made under an incorrect price.
                  In such event, if available, we will offer you the opportunity to keep your Booking at the amended price or
                  you may cancel your Booking without penalty.
                </p>
                <p><strong>OUR LIABILITY TO YOU </strong>
                </p>
                <p>The hotels and other suppliers providing Hotel Reservations to us are independent contractors and not agents
                  or employees of us, or our Group Members. </p>
                <p>We and our Group Members are not liable for:
                </p>
                <ul>
                  <li>the acts, errors, omissions, representations, warranties, breaches or negligence of any Affiliate or&nbsp;
                    the suppliers of the Hotel Reservations; or </li>
                  <li>for any personal injury, death, property damage, or other damages or expenses resulting there from.&nbsp;
                  </li>
                </ul>
                <p>We and our Group Members have no liability and will make no refund in the event of any:
                </p>
                <ul>
                  <li>delay;
                  </li>
                  <li>cancellation;
                  </li>
                  <li>overbooking;
                  </li>
                  <li>strike;
                  </li>
                  <li>force majeure; or
                  </li>
                  <li>other causes beyond our direct control; &nbsp;
                  </li>
                </ul>
                <p>and we have no responsibility for any additional expenses, omissions, delays, re-routing or acts of any
                  government or authority.&nbsp;
                </p>
                <p>To the fullest extent permitted by applicable law, in no event shall we or our Group Members be liable for
                  any direct, indirect, punitive, incidental, special or consequential damages arising out of, or in any way
                  connected with:
                </p>
                <ul>
                  <li>your access to our Information; or
                  </li>
                  <li>the delay or inability to access our Information (including, but not limited to, any computer viruses,
                    information, software, linked sites, and Bookings);
                  </li>
                </ul>
                <p>whether based on negligence, contract, tort, strict liability, consumer protection law, or otherwise, and
                  even if we or our Group Members have been advised of the possibility of such damages.
                </p>
                <p>If, despite the limitation above, we or our Group Members are found liable for any loss or damage which
                  arises out of or is in any way connected with any of the occurrences described above, then the liability of
                  us, or our Group Members will in no event exceed, in the aggregate, the greater of:
                </p>
                <ul>
                  <li>the Booking fee you paid to us, our Group Member or Affiliate in connection with the relevant Booking; or
                  </li>
                  <li>One Hundred Dollars (US$100.00) or the equivalent in local currency.
                  </li>
                </ul>
                <p>We do not exclude or limit in any way our liability for anything which cannot be excluded by applicable law.
                </p>
                <p>The limitation of liability reflects the allocation of risk between you and us.
                </p>
                <p>The limitations specified in this section will survive and apply even if any limited remedy specified in
                  these Terms is found to have failed of its essential purpose.
                </p>
                <p>The limitations of liability provided in these Terms inure to the benefit of us and our Group Members.
                </p>
                <p><strong>INDEMNIFICATION </strong>
                </p>
                <p>You agree to defend and indemnify us, our Group Members and any of their officers, directors, employees and
                  agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties
                  or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting
                  fees, brought by third parties as a result of:
                </p>
                <ul>
                  <li>your breach of these Terms;
                  </li>
                  <li>your violation of any law or the rights of a third party; or
                  </li>
                  <li>your use of our Information.
                  </li>
                </ul>
                <p><strong>INTELLECTUAL PROPERTY RIGHTS</strong>
                </p>
                <p>Our Information (including, but not limited to, price and availability of Hotel Reservations) is owned by us
                  or our suppliers and providers.
                </p>
                <p>Whilst we agree that you can use our Information to make limited copies of your travel itinerary and any
                  other information relevant to your Booking, you agree not to otherwise modify, copy, distribute, transmit,
                  display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any
                  information, software, products, or services relating to and/or including the Hotel Reservations and our
                  Information.
                </p>
                <p><strong>COPYRIGHT AND TRADEMARK NOTICES </strong>
                </p>
                <p>All contents of our Information are &copy;2018 Expedia, Inc.&nbsp; All rights reserved. Expedia, Expedia
                  Affiliate Network, EAN, Travelnow, Hotels.com, the Expedia Affiliate Network logo, the Travelnow logo and the
                  Hotels.com logo are either registered trademarks or trademarks of Expedia, Inc. in the U.S. and/or other
                  countries. Other logos and product and company names mentioned herein may be the trademarks of their
                  respective owners.&nbsp; We are not responsible for content on websites operated by third parties.
                  &nbsp;&nbsp;
                </p>
                <p>OpenStreetMap geo data used in mapping is (c) OpenStreetMap contributors and available under the <a
                    href="http://opendatacommons.org/licenses/odbl/">Open Database License (ODbL)</a></p>
                <p>If you are aware of an infringement of our brand, please let us know by e-mailing us at hotline@expedia.com.
                  We only address messages concerning brand infringement at this email address.
                </p>
                <p><strong>APPLICABLE LAW</strong>
                </p>
                <p>Our Information is made available by a U.S. entity and these Terms are governed by the laws of the State of
                  Washington, USA. You hereby consent to the exclusive jurisdiction and venue of courts in King County,
                  Washington, USA, in all disputes arising out of these Terms or relating to the use of our Information. Use of
                  our Information is unauthorized in any jurisdiction that does not give effect to all provisions of these
                  Terms.
                </p>
                <p><strong>DISPUTES </strong>
                </p>
                <p>We and our Group Members are committed to customer satisfaction, so if you have a problem or dispute, we will
                  try to resolve your concerns. If we are unsuccessful, you may pursue claims as explained in this section.
                </p>
                <p>You agree to give us an opportunity to resolve any disputes or claims relating in any way to our Information,
                  Bookings, any dealings with our Customer Support Agents, any Hotel Reservations, or any representations made
                  by us (<strong>Claims</strong>) by contacting us through the customer service details provided to you at the
                  time of booking. If we are not able to resolve your Claims within 60 days, you may seek relief through
                  arbitration or in small claims court, as set forth below.
                </p>
                <p>Any and all Claims will be resolved by binding arbitration, rather than in court, except you may assert
                  Claims on an individual basis in small claims court if they qualify. This includes any Claims you assert
                  against us, our subsidiaries, travel suppliers or any companies offering products or services through us
                  (which are beneficiaries of this arbitration agreement). This also includes any Claims that arose before you
                  accepted these Terms, regardless of whether prior versions of these Terms required arbitration.
                </p>
                <p>There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an
                  arbitrator can award on an individual basis the same damages and relief as a court (including statutory
                  damages, attorneys’ fees and costs), and must follow and enforce these Terms as a court would.&nbsp;
                </p>
                <p>Arbitrations will be conducted by the American Arbitration Association (AAA) under its rules, including the
                  AAA Consumer Rules. The AAA's rules are available at <a href="http://www.adr.org">www.adr.org</a> or by
                  calling 1-800-778-7879. Payment of all filing, administration and arbitrator fees will be governed by the
                  AAA's rules, except as provided in this section. If your total Claims seek less than $10,000, we will
                  reimburse you for filing fees you pay to the AAA and will pay arbitrator’s fees, unless the arbitrator
                  determines your Claims are frivolous. You may choose to have an arbitration conducted by telephone, based on
                  written submissions, or in person in the state where you live or at another mutually agreed location.
                </p>
                <p>To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your Claims
                  to “Expedia Legal: Arbitration Claim Manager,” at Expedia, Inc.,&nbsp;<span
                      class="TrackChangeTextInsertion TrackedChange  BCX0 SCXW136810903"><span class="TextRun  BCX0 SCXW136810903"
                                                                                               lang="EN-US"><span class="NormalTextRun  BCX0 SCXW136810903">1111 Expedia Group Way
                W</span></span></span><span class="TrackChangeTextInsertion TrackedChange  BCX0 SCXW136810903"><span
                      class="TextRun  BCX0 SCXW136810903" lang="EN-US"><span
                      class="NormalTextRun  BCX0 SCXW136810903">est</span></span></span><span
                      class="TrackChangeTextInsertion TrackedChange  BCX0 SCXW136810903"><span class="TextRun  BCX0 SCXW136810903"
                                                                                               lang="EN-US"><span class="NormalTextRun  BCX0 SCXW136810903">, Seattle, WA 98119</span></span></span>. If
                  we request arbitration against you, we will give you notice at the email address or street address you have
                  provided. A party requesting arbitration must also provide a copy of the request to the AAA, at Case Filing
                  Services, 1101 Laurel Oak Road, Suite 100, Voorhees, NJ 08043 or online at <a
                      href="http://www.adr.org">www.adr.org</a> or at any AAA office.
                </p>
                <p>Any and all proceedings to resolve Claims will be conducted only on an individual basis and not in a class,
                  consolidated or representative action.&nbsp; If for any reason a Claim proceeds in court rather than in
                  arbitration we each waive any right to a jury trial. The Federal Arbitration Act and federal arbitration law
                  apply to this agreement. An arbitration decision may be confirmed by any court with competent jurisdiction.
                </p>
                <p><strong>GENERAL </strong>
                </p>
                <p>You agree that no joint venture, partnership, or employment relationship exists between you and us or our
                  Group Members as a result of these Terms or your access to our Information. </p>
                <p>You acknowledge that Travelscape, LLC or VacationSpot, SL (or any other Group Member as we designate) shall
                  be treated by any tax authority in any pertinent jurisdiction as the supplier of the Hotel Reservation for
                  Prepaid Bookings and Packages for VAT (Value Added Tax) purposes as applied by Directive 2006/112/EC.
                </p>
                <p>Our performance of these Terms are subject to existing laws and legal process, and nothing contained in these
                  Terms limit our right to comply with law enforcement or other governmental or legal requests or requirements
                  relating to your use of our Information or information provided to or gathered by us with respect to such use.
                </p>
                <p>We may transfer our rights and obligations under these Terms to another organisation but this will not affect
                  your rights or our obligations under these Terms. You may only transfer your rights or your obligations under
                  these Terms to another person if we agree in writing.
                </p>
                <p>To the extent allowed by applicable law, you agree that you will bring any claim or cause of action arising
                  from or relating to your access or use of our Information within two (2) years from the date on which such
                  claim or action arose or accrued or such claim or cause of action will be irrevocably waived. </p>
                <p>If any part of these Terms is found to be invalid, illegal or unenforceable, the validity, legality and
                  enforceability of the remaining provisions will not in any way be affected or impaired. Our failure or delay
                  in enforcing any provision of these Terms at any time does not waive our right to enforce the same or any
                  other provisions in the future.
                </p>
                <p>These Terms (and any other terms and conditions referenced herein) constitute the entire agreement between
                  you and us with respect to our Information and any Bookings and it supersedes all prior or contemporaneous
                  communications and proposals, whether electronic, oral, or written, between you and us with respect to our
                  Information and/or Booking. A printed version of these Terms and of any notice given in electronic form shall
                  be admissible in judicial or administrative proceedings based upon or relating to these Terms to the same
                  extent and subject to the same conditions as other business documents and records originally generated and
                  maintained in printed form. </p>
                <p>Fictitious names of companies, products, people, characters, and/or data mentioned in our Information are not
                  intended to represent any real individual, company, product, or event. </p>
                <p>Any rights not expressly granted herein are reserved. </p>
                <p><strong>OUR DETAILS</strong>
                </p>
                <p>Travelscape LLC is a registered seller of travel in each of the states listed below:
                </p>
                <ul>
                  <li>California registration number: 2083930-50
                  </li>
                  <li>Florida registration number: ST36407
                  </li>
                  <li>Iowa registration number: 677
                  </li>
                  <li>Nevada registration number: 20020759
                  </li>
                  <li>Washington registration number: 602-617-174
                  </li>
                </ul>
                <p>Registration as a seller of travel in California does not constitute the state's approval.
                </p>
                <p>New York State Tax Registration:
                </p>
                <p>Travelscape’s (d/b/a Expedia Travel) New York sales tax vendor registration number is 880392667 and its New
                  York City hotel occupancy tax registration number is 033960.
                </p>
                <p>Please click here for additional information: <br></p>
                <p><span class="MsoHyperlink"><a
                    href="https://images.trvl-media.com/media/content/expus/graphics/other/travelscape_nys_certificate_of_authority.pdf"
                    target="_blank">New York State Certificate of Authority</a></span> <br></p>
                <p><span class="MsoHyperlink"><a
                    href="https://images.trvl-media.com/media/content/expus/graphics/other/travelscape_nyc_certificate_of_authority.pdf"
                    target="_blank">New York City Certificate of Authority</a></span><span></span>
                </p>
                <p>&copy;2018 EXPEDIA, INC.&nbsp;&nbsp;&nbsp; All rights reserved.
                </p>
                <p><br></p>
                <p><br></p>
              </div>
              <div id="_com_1" class="msocomtxt" language="JavaScript" onmouseover="msoCommentShow('_anchor_1','_com_1')"
                   onmouseout="msoCommentHide('_com_1')">
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "EpsTerms",
  data(){
    return {
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
    }
  }
}
</script>

<style scoped>

</style>